import { combineReducers } from 'redux';
import auth from './authReducer';
import counties from './countiesReducer';
import advertise from './advertiseReducer';

export default combineReducers({
  auth,
  counties,
  advertise,
});
