import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import AppRouter from './AppRouter';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Route path="/" component={AppRouter} />
      </BrowserRouter>
    </>
  );
};

export default App;
