import fetch from 'cross-fetch';
import * as actionType from 'service/actionTypes';
import { apiHost } from 'service/config';


export const getCurrentUserRequest = () => async (dispatch) => {
  dispatch({ type: actionType.START_GETTING_CURRENT_USER });
  const authToken = localStorage.getItem('authToken');
  try {
    const response = await fetch(`${apiHost}api/v1/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!response.ok) {
      return dispatch({ type: actionType.FAILED_GETTING_CURRENT_USER });
    }
    const data = await response.json();
    return dispatch({ type: actionType.SUCCESS_GETTING_CURRENT_USER, response: data });
  } catch (error) {
    return dispatch({ type: actionType.FAILED_GETTING_CURRENT_USER });
  }
};

export const getProfileRequest = async (userName) => {
  try {
    const response = await fetch(`${apiHost}api/v1/users/profile/${userName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      return { success: false, data: {} };
    }
    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    return { success: false, data: {} };
  }
};

export const updateProfileRequest = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_UPDATE_PROFILE });
  const authToken = localStorage.getItem('authToken');
  try {
    const response = await fetch(`${apiHost}api/v1/users/profile`, {
      method: 'PUT',
      body: payload,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_UPDATE_PROFILE });
      return { success: false };
    }
    const data = await response.json();
    dispatch({ type: actionType.SUCCESS_GETTING_CURRENT_USER, response: data });
    return { success: true, data };
  } catch (error) {
    dispatch({ type: actionType.FAILED_UPDATE_PROFILE });
    return { success: false };
  }
};

export const updatePassword = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_UPDATE_PASSWORD });
  const authToken = localStorage.getItem('authToken');
  try {
    const response = await fetch(`${apiHost}api/v1/users/update-password`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_UPDATE_PASSWORD });
      return { success: false };
    }
    dispatch({ type: actionType.SUCCESS_UPDATE_PASSWORD });
    return { success: true };
  } catch (error) {
    dispatch({ type: actionType.FAILED_UPDATE_PASSWORD });
    return { success: false };
  }
};
