import React from 'react';
import cn from 'classnames';
import BrandLogo from './components/BrandLogo';
import NavigationOptions from './components/NavigationOptions';

import globalStyles from 'assets/styles.module.scss';
import styles from './styles.module.scss';

const classes = cn(globalStyles.contentWrapper, styles.navWrapContainer)

const Navigation = () => (
  <header className={styles.header}>
    <nav className={classes}>
      <div className={styles.navContainer}>
        <BrandLogo />
        <NavigationOptions />
      </div>
    </nav>
  </header>
);

export default React.memo(Navigation);
