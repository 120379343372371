import '@babel/polyfill';
import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import ReactDOM from 'react-dom';
import rootReducer from './reducers/rootReducer';
import 'assets/styles.scss';
import App from './App';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk),
);

ReactDOM.render((
  <Provider store={store}>
    <App />
  </Provider>
), document.getElementById('root'));

