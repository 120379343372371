// Auth types
export const START_SIGN_IN = 'START_SIGN_IN';
export const FAILED_SIGN_IN = 'FAILED_SIGN_IN';
export const SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN';
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT';
export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
export const FAILED_RESET_PASSWORD = 'FAILED_RESET_PASSWORD';
export const START_RECOVERY_PASSWORD = 'START_RECOVERY_PASSWORD';
export const FAILED_RECOVERY_PASSWORD = 'FAILED_RECOVERY_PASSWORD';
export const SUCCESS_RECOVERY_PASSWORD = 'SUCCESS_RECOVERY_PASSWORD';
export const START_SEND_CODE = 'START_SEND_CODE';
export const SUCCESS_SEND_CODE = 'SUCCESS_SEND_CODE';
export const FAILED_SEND_CODE = 'FAILED_SEND_CODE';
export const START_SIGN_UP = 'START_SIGN_UP';
export const FAILED_SIGN_UP = 'FAILED_SIGN_UP';
export const SUCCESS_SIGN_UP = 'SUCCESS_SIGN_UP';
export const START_CONFIRM_USER = 'START_CONFIRM_USER';
export const SUCCESS_CONFIRM_EMAIL = 'SUCCESS_CONFIRM_EMAIL';
export const FAILED_CONFIRM_USER = 'FAILED_CONFIRM_USER';


// components types
export const START_GETTING_CURRENT_USER = 'START_GETTING_CURRENT_USER';
export const FAILED_GETTING_CURRENT_USER = 'FAILED_GETTING_CURRENT_USER';
export const SUCCESS_GETTING_CURRENT_USER = 'SUCCESS_GETTING_CURRENT_USER';
export const START_UPDATE_PROFILE = 'START_UPDATE_PROFILE';
export const FAILED_UPDATE_PROFILE = 'FAILED_UPDATE_PROFILE';
export const START_UPDATE_PASSWORD = 'START_UPDATE_PASSWORD';
export const SUCCESS_UPDATE_PASSWORD = 'SUCCESS_UPDATE_PASSWORD';
export const FAILED_UPDATE_PASSWORD = 'FAILED_UPDATE_PASSWORD';

// Counties types
export const START_GETTING_COUNTIES = 'START_GETTING_COUNTIES';
export const FAILED_GETTING_COUNTIES = 'FAILED_GETTING_COUNTIES';
export const SUCCESS_GETTING_COUNTIES = 'SUCCESS_GETTING_COUNTIES';


// Advertise types
export const START_CREATE_ADVERTISE = 'START_CREATE_ADVERTISE';
export const FAILED_CREATE_ADVERTISE = 'FAILED_CREATE_ADVERTISE';
export const SUCCESS_CREATE_ADVERTISE = 'SUCCESS_CREATE_ADVERTISE';
export const START_GETTING_ADVERTISES = 'START_GETTING_ADVERTISES';
export const FAILED_GETTING_ADVERTISES = 'FAILED_GETTING_ADVERTISES';
export const SUCCESS_GETTING_ADVERTISES = 'SUCCESS_GETTING_ADVERTISES';
export const START_DELETE_ADVERTISE = 'START_DELETE_ADVERTISE';
export const FAILED_DELETE_ADVERTISE = 'FAILED_DELETE_ADVERTISE';
export const SUCCESS_DELETE_ADVERTISE = 'SUCCESS_DELETE_ADVERTISE';
export const START_GETTING_MODERATED_ADVERTISES = 'START_GETTING_MODERATED_ADVERTISES';
export const FAILED_GETTING_MODERATED_ADVERTISES = 'FAILED_GETTING_MODERATED_ADVERTISES';
export const SUCCESS_GETTING_MODERATED_ADVERTISES = 'SUCCESS_GETTING_MODERATED_ADVERTISES';
export const START_UPDATE_ADVERTISE = 'START_UPDATE_ADVERTISE';
export const FAILED_UPDATE_ADVERTISE = 'FAILED_UPDATE_ADVERTISE';
export const SUCCESS_UPDATE_ADVERTISE = 'SUCCESS_UPDATE_ADVERTISE';
export const RESET_ADVERTISES = 'RESET_ADVERTISES';
export const SET_COUNTY = 'SET_COUNTY';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_OFFSET = 'SET_OFFSET';
