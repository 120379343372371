export const defaultOffset = 15;

export const MIN_PASSWORD_LENGTH = 6;

export const defaultCountyOption = {
  label: 'Kogu Eesti',
  value: 0,
};

export const appBaseLinks = [
  'policy',
  'terms',
  'contacts',
];

export const emailRegexp = /^[-!#$%&'*+/\w=?^_{|}~](\.?[-!#$%&'*+/\w=?^_{|}~])*@\w(-?\w)*(\.[a-zA-Z](-?\w)*)+$/;

export const userNameRegexp = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
