import fetch from 'cross-fetch';
import * as actionType from 'service/actionTypes';
import { apiHost } from 'service/config';

export const getCountiesRequest = () => async (dispatch) => {
  dispatch({ type: actionType.START_GETTING_COUNTIES });
  try {
    const response = await fetch(`${apiHost}api/v1/index/counties`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_GETTING_COUNTIES });
      return { success: false, error: 'Something went wrong' };
    }
    const data = await response.json();
    dispatch({ type: actionType.SUCCESS_GETTING_COUNTIES, response: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: actionType.FAILED_GETTING_COUNTIES });
    return { success: false, error: 'Something went wrong' };
  }
};
