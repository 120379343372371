import * as React from 'react';
import { confirmUserRequest } from 'actions/authRequests';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useConfirmEmail = () => {
  const [confirmed, setConfirmed] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  React.useEffect(() => {
    if (queryParams.has('token')) {
      (async function f() {
        const response = await dispatch(confirmUserRequest({
          token: queryParams.get('token'),
        }));
        if (response.success) {
          setConfirmed(true);
        }
      }());
    }
  }, []);

  return confirmed;
};
