import * as React from 'react';
import HeadMenu from '../HeadMenu';
import { useOutsideClick } from 'hooks';

import styles from './styles.module.scss';

const NavigationOptions = () => {
  const ref = React.useRef(null);
  const [isActiveMenu, setActiveMenu] = React.useState(false);
  const handleClose = () => setActiveMenu(false);
  useOutsideClick(ref, handleClose);

  return (
    <div
      className={styles.navigationItem}
      onClick={() => setActiveMenu(!isActiveMenu)}
      ref={ref}
    >
      <img src="/images/svg/profile-icon.svg" className={styles.image}/>
      {isActiveMenu
        && <HeadMenu closeMenu={handleClose} />}
    </div>
  );
};

export default React.memo(NavigationOptions);
