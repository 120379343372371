import * as React from 'react';

import { ToastContainer as Toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

const options = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: true,
  draggable: true,
  pauseOnHover: true,
  closeButton: false,
  toastClassName: 'toastify-content',
};

const ToastContainer = () => <Toast {...options} />;
export default React.memo(ToastContainer);
