import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actionType from 'service/actionTypes';

import styles from './styles.module.scss'

const BrandLogo = () => {
  const dispatch = useDispatch();

  const handleClick = () =>
    dispatch({ type: actionType.SET_SEARCH_QUERY, searchQuery: '' });

  return (
    <div className={styles.brand}>
      <Link to="/" onClick={handleClick}>
        <img className={styles.img} src="/images/logo.png" />
      </Link>
    </div>
  );
};

export default React.memo(BrandLogo);
