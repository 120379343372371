import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AppContainer from './Layout/AppContainer';
import WavesLoader from './components/WavesLoader';

const Search = React.lazy(() => import('pages/Search'));
const Profile = React.lazy(() => import('pages/Profile'));
const Contacts = React.lazy(() => import('pages/Other/Contacts'));
const Policy = React.lazy(() => import('pages/Other/Policy'));
const Terms = React.lazy(() => import('pages/Other/Terms'));
const Moderator = React.lazy(() => import('pages/Moderator/Advertise'));
const Settings = React.lazy(() => import('pages/Settings'));

const AppRouter = (props) => {
  const history = useHistory();
  const isModerator = localStorage.getItem('isModerator') === 'true';
  const authToken = localStorage.getItem('authToken');
  const authRoutes = [
    {
      route: '/login',
      component: React.lazy(() => import('pages/Authentication/forms/Login/Form')),
    },
    {
      route: '/signup',
      component: React.lazy(() => import('pages/Authentication/forms/SignUp/Form')),
    },
    {
      route: '/reset-password',
      component: React.lazy(() => import('pages/Authentication/forms/ResetPass/Form')),
    },
    {
      route: '/recovery-password',
      component: React.lazy(() => import('./pages/Authentication/forms/RecoveryPass/Form')),
    },
  ];

  const renderAuthRoutes = () => authRoutes.map(r =>
    <Route key={r.route} path={r.route} component={r.component} />);

  const { pathname, search } = props.location;

  // redirect to confirmation email page from mailbox link
  if (pathname === '/confirm-email') {
    const url = authToken ? '/settings/profile' : '/login';
    history.push(`${url}${search}`);
  }

  return (
    <AppContainer>
      <React.Suspense fallback={<WavesLoader />}>
        <Switch>
          {!authToken && renderAuthRoutes()}
          {isModerator && <Route path="/moderate" component={Moderator} />}
          {authToken && <Route path="/settings" component={Settings} />}
          <Route path="/policy" component={Policy} />
          <Route path="/terms" component={Terms} />
          <Route path="/contacts" component={Contacts} />
          <Route exact path="/" component={Search} />
          <Route path="/:username" component={Profile} />
        </Switch>
      </React.Suspense>
    </AppContainer>
  );
};

export default React.memo(AppRouter);
