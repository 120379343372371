/* eslint-disable no-param-reassign */
import produce, { setAutoFreeze } from 'immer';
import * as actionType from 'service/actionTypes';

setAutoFreeze(false);

const initialState = {
  counties: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SUCCESS_GETTING_COUNTIES:
      return produce(state, (draftState) => {
        if (action.response.length > 0) {
          draftState.counties = action.response;
        }
      });
    default:
      return state;
  }
};
