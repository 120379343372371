import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOutProcess } from 'actions/authRequests'
import { appBaseLinks } from 'service/constants';
import { firstStrUpper } from 'service/helpers';

import styles from './styles.module.scss';

const RenderLink = ({ path, text }) => (
  <Link to={`/${path}`}>
    <div className={styles.headerMenuItem}>
      {text}
    </div>
  </Link>
);

const HeadMenu = (props) => {
  const isLoaded = useSelector((state) => state.auth.isLoadedProfile);
  const userName = useSelector((state) => state.auth.currentUser.userName);
  const dispatch = useDispatch();
  const history = useHistory();

  const isModerator = localStorage.getItem('isModerator') === 'true';
  const isAuthorized = localStorage.getItem('authToken') || isLoaded;

  const logout = () => {
    dispatch(logOutProcess());
    history.push('/login');
  };

  return (
    <div className={styles.headerMenu} onClick={props.closeMenu}>
      {isAuthorized
        ? (
          <>
            <RenderLink path={userName} text="My Account" />
            <RenderLink path="settings" text="Settings" />
          </>
        ) : (
          <>
            <RenderLink path="login" text="Log In" />
            <RenderLink path="signup" text="Sign Up" />
          </>
        )}
      {isModerator && <RenderLink path="moderate" text="Moderate" />}
      <div className={styles.section}>
        {appBaseLinks.map(link =>
          <RenderLink key={link} path={link} text={firstStrUpper(link)} />)}
      </div>
      {isAuthorized
        && (
          <div className={styles.section}>
            <div className={styles.headerMenuItem} onClick={logout}>Log out</div>
          </div>
        )}
    </div>
  );
};

export default React.memo(HeadMenu);
