import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUserRequest } from 'actions/profileRequests';
import { getCountiesRequest } from 'actions/commonRequests';
import ToastContainer from 'components/ToastContainer';
import Navigation from '../Navigation';

import 'react-dropdown/style.css';
import 'react-phone-input-2/lib/style.css';
import globalStyles from 'assets/styles.module.scss';
import styles from './style.module.scss';

const AppContainer = (props) => {
  const { isLoadedProfile } = useSelector((state) => state.auth);
  const isAuthorized = isLoadedProfile || !!localStorage.getItem('authToken');
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthorized) {
      dispatch(getCurrentUserRequest());
    }
  }, [isAuthorized]);

  React.useEffect(() => {
    dispatch(getCountiesRequest());
  }, []);

  return (
    <div>
      <Navigation />
      <main className={styles.mainContainer}>
        <div className={globalStyles.contentWrapper}>
          {props.children}
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default React.memo(AppContainer);
