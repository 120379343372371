import React from 'react';

import styles from './styles.module.scss';

const WavesLoader = () => (
  <div className={styles.customWavesLoader}>
    <div className={styles.wavesLoader} />
  </div>
);

export default React.memo(WavesLoader);
