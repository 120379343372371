/* eslint-disable no-param-reassign */
import produce, { setAutoFreeze, createDraft } from 'immer';
import { uniqBy } from 'lodash';
import * as actionType from 'service/actionTypes';
import { defaultOffset } from 'service/constants';

setAutoFreeze(false);

const initialState = {
  collection: [],
  searchQuery: '',
  collectionSize: 0,
  offset: 0,
  countyId: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SUCCESS_GETTING_ADVERTISES:
      return produce(state, (draftState) => {
        const { collection, collection_size: size } = action.data;
        if (size === 0) {
          draftState.collection = [];
        } else if (draftState.offset === 0) {
          draftState.collection = collection;
        } else {
          draftState.collection = uniqBy(state.collection.concat(collection), 'uuid');
        }
        draftState.offset += defaultOffset;
        draftState.collectionSize = size;
      });
    case actionType.SET_SEARCH_QUERY:
      return produce(state, (draftState) => {
        draftState.searchQuery = action.searchQuery;
      });
    case actionType.SET_COUNTY:
      return produce(state, (draftState) => {
        draftState.countyId = action.countyId;
      });
    case actionType.SET_OFFSET:
      return produce(state, (draftState) => {
        draftState.offset = action.offset;
      });
    case actionType.RESET_ADVERTISES:
      return produce(state, () =>
        createDraft(initialState));
    case actionType.SUCCESS_CREATE_ADVERTISE:
      return produce(state, (draftState) => {
        draftState.collection.unshift(action.data);
      });
    case actionType.SUCCESS_DELETE_ADVERTISE:
      return produce(state, (draftState) => {
        const { collection, collectionSize } = draftState;
        const adIdx = collection.findIndex(el => el.uuid === action.advertiseId);
        if (adIdx !== -1) {
          collection.splice(adIdx, 1);
          draftState.collectionSize = collectionSize - 1;
        }
      });
    case actionType.SUCCESS_UPDATE_ADVERTISE:
      return produce(state, (draftState) => {
        const { collection } = draftState;
        const idx = collection.findIndex(ad => ad.uuid === action.data.uuid);
        if (idx !== -1) {
          collection[idx] = action.data;
        }
      });
    default:
      return state;
  }
};
