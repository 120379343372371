/* eslint-disable no-param-reassign */
import produce, { setAutoFreeze } from 'immer';
import * as actionType from 'service/actionTypes';
import { mapObjKeysToCamelCase } from 'service/helpers';

setAutoFreeze(false);

const initialState = {
  currentUser: {
    id: undefined,
    email: '',
    fullName: '',
    userName: '',
    phone: '',
    photo: '',
    phoneCountryCode: 'ee',
    publicEmail: false,
    publicPhone: false,
    unconfirmedEmail: '',
  },
  isLoadedProfile: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.START_SIGN_IN:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case actionType.SUCCESS_SIGN_IN:
      return produce(state, (draftState) => {
        const { data } = action.response;
        draftState.currentUser = mapObjKeysToCamelCase(data);
        draftState.isLoadedProfile = true;
        draftState.loading = false;
        localStorage.setItem('authToken', action.response.access_token);
        localStorage.setItem('isModerator', data.moderator);
      });
    case actionType.SUCCESS_LOGOUT:
      return produce(state, (draftState) => {
        draftState.isLoadedProfile = false;
        draftState.currentUser = initialState.currentUser;
        localStorage.removeItem('authToken');
        localStorage.removeItem('isModerator');
      });
    case actionType.START_GETTING_CURRENT_USER:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case actionType.SUCCESS_GETTING_CURRENT_USER:
      return produce(state, (draftState) => {
        const { response } = action;
        draftState.isLoadedProfile = true;
        draftState.loading = false;
        draftState.currentUser = mapObjKeysToCamelCase(response);
        localStorage.setItem('isModerator', response.moderator);
      });
    case actionType.SUCCESS_CONFIRM_EMAIL:
      return produce(state, (draftState) => {
        const { currentUser } = draftState;
        if (currentUser.unconfirmedEmail) {
          currentUser.email = currentUser.unconfirmedEmail;
          currentUser.unconfirmedEmail = '';
        }
      });
    default:
      return state;
  }
};
