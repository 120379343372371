import fetch from 'cross-fetch';
import * as actionType from 'service/actionTypes';
import { apiHost } from 'service/config';

export const signInRequest = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_SIGN_IN });
  try {
    const response = await fetch(`${apiHost}api/v1/token`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_SIGN_IN });
      return { success: false };
    }
    const data = await response.json();
    dispatch({ type: actionType.SUCCESS_SIGN_IN, response: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: actionType.FAILED_SIGN_IN });
    return { success: false };
  }
};

export const resetPasswordRequest = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_RESET_PASSWORD });

  try {
    const response = await fetch(`${apiHost}api/v1/users/password`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_RESET_PASSWORD });
      return { success: false };
    }
    dispatch({ type: actionType.SUCCESS_RESET_PASSWORD });
    return { success: true };
  } catch (error) {
    dispatch({ type: actionType.FAILED_RESET_PASSWORD });
    return { success: false };
  }
};

export const recoveryPasswordRequest = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_RECOVERY_PASSWORD });

  try {
    const response = await fetch(`${apiHost}api/v1/users/password`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_RECOVERY_PASSWORD });
      const error = Object.values(data.message).join('\n');
      return { success: false, error };
    }
    dispatch({ type: actionType.SUCCESS_RECOVERY_PASSWORD });
    return { success: true };
  } catch (error) {
    return { success: false, error: 'Something went wrong' };
  }
};

export const sendCodeRequest = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_SEND_CODE });

  try {
    const response = await fetch(`${apiHost}api/v1/users/registration/send_code`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_RECOVERY_PASSWORD });
      return { success: false, message: data.message };
    }
    dispatch({ type: actionType.SUCCESS_SEND_CODE });
    return { success: true };
  } catch (error) {
    dispatch({ type: actionType.FAILED_SEND_CODE });
    return { success: false, message: { phone: 'Something went wrong' } };
  }
};

export const signUpRequest = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_SIGN_UP });

  try {
    const response = await fetch(`${apiHost}api/v1/users`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_SIGN_UP });
      const error = Object.values(data.message).join('\n');
      return { success: false, error };
    }
    dispatch({ type: actionType.SUCCESS_SIGN_UP });
    return { success: true };
  } catch (error) {
    dispatch({ type: actionType.START_SIGN_UP });
    return { success: false };
  }
};

export const confirmUserRequest = (payload) => async (dispatch) => {
  dispatch({ type: actionType.START_CONFIRM_USER });

  try {
    const response = await fetch(`${apiHost}api/v1/users/confirmation`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      dispatch({ type: actionType.FAILED_CONFIRM_USER });
      return { success: false };
    }
    dispatch({ type: actionType.SUCCESS_CONFIRM_EMAIL });
    return { success: true };
  } catch (error) {
    dispatch({ type: actionType.FAILED_CONFIRM_USER });
    return { success: false };
  }
};

export const logOutProcess = () => (dispatch) => {
  dispatch({ type: actionType.SUCCESS_LOGOUT });
  dispatch({ type: actionType.SET_SEARCH_QUERY, searchQuery: '' });
};