import { capitalize } from 'lodash';
import { emailRegexp, MIN_PASSWORD_LENGTH, userNameRegexp } from './constants';

export const convertCountyOptions = (collection) => collection.map((el) => (
  { label: el.name, value: el.id }
));

export const prepareStatusesDropdown = (collection) => collection.map(el => (
  { label: capitalize(el), value: el }
));

export const prepareParams = (state) => {
  const { countyId, searchQuery, offset } = state;
  let params = `?offset=${offset}`;
  if (countyId) {
    params += `&county_id=${countyId}`;
  }
  if (searchQuery) {
    params += `&query=${searchQuery}`;
  }
  return params;
};


export const firstStrUpper = (string) => {
  const strLowercase = string.split(' ').map(str => str.toLowerCase()).join(' ');
  return strLowercase.charAt(0).toUpperCase() + strLowercase.slice(1);
};

export const mapObjKeysToCamelCase = (data) => {
  const snakeToCamel = (str) =>
    str.replace(/([-_][a-z])/g, (group) => group.toUpperCase()
      .replace('-', '')
      .replace('_', ''));

  const newObj = {};

  Object.keys(data).forEach((key) => {
    newObj[snakeToCamel(key)] = data[key];
  });
  return newObj;
};
